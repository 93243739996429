import PropTypes from 'prop-types'
import React from 'react'

const SectionPricingPlansBox = ({
  buttonElement,
  list,
  price,
  priceNote,
  title,
}) => (
  <div className="flex-1 flex flex-col justify-between py-2 bg-gray-400 rounded-2xl">
    <div className="pl-14 pr-6">
      <h2 className="font-title text-2xl font-bold uppercase">{title}</h2>
      <div className="flex mt-2 items-center">
        <span className="pr-4 font-title text-4xl text-blue">{price}</span>
        <span className="text-sm font-light leading-3.5">{priceNote}</span>
      </div>
      <ul className="mt-6 list-check font-light">
        {list.map(item => (
          <li key={`${title}-${item}`}>{item}</li>
        ))}
      </ul>
    </div>
    <div className="mx-auto my-8">{buttonElement}</div>
  </div>
)

SectionPricingPlansBox.defaultProps = {
  priceNote: null,
}

SectionPricingPlansBox.propTypes = {
  buttonElement: PropTypes.element.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  price: PropTypes.string.isRequired,
  priceNote: PropTypes.any,
  title: PropTypes.string.isRequired,
}

export { SectionPricingPlansBox }
