import PropTypes from 'prop-types'
import React from 'react'

const HeaderSubPage = ({ children, title }) => (
  <header className="bg-blue-gradient">
    <div className="container">
      <div className="min-h-40 flex flex-col justify-center align-center py-10">
        <h1 className="font-title text-6xl font-bold text-white text-center">
          {title}
        </h1>
        {children}
      </div>
    </div>
  </header>
)

HeaderSubPage.defaultProps = {
  children: null,
}

HeaderSubPage.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
}

export { HeaderSubPage }
