import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'

import { SectionPricingPlansBox } from './section-pricing-plans-box'

import { Button } from '../common/button'

const SectionPricingPlans = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionPricingPlans',
  })

  const [isMonthly, setMonthly] = useState(false)

  return (
    <section className="-mt-72 mb-10">
      <div className="w-full lg:w-3/5 mx-auto flex justify-center space-x-5">
        <SectionPricingPlansBox
          buttonElement={
            <Button
              className="w-44 px-0"
              color="gray-600"
              size="md"
              title={t('free.button.text')}
              to="https://app.flagis.com/sign-up"
            />
          }
          list={[
            t('free.list.0'),
            t('free.list.1'),
            t('free.list.2'),
            t('free.list.3'),
          ]}
          price={t('free.price')}
          title={t('free.title')}
        />
        <SectionPricingPlansBox
          buttonElement={
            <Button
              className="w-44"
              color="green"
              size="md"
              title={t('premium.button.text')}
              to="https://app.flagis.com/sign-up"
            />
          }
          list={[
            t('premium.list.0'),
            t('premium.list.1'),
            t('premium.list.2'),
            t('premium.list.3'),
          ]}
          price={t(
            isMonthly ? 'premium.monthly.price' : 'premium.annually.price',
          )}
          priceNote={
            <>
              <span>
                {t(
                  isMonthly
                    ? 'premium.monthly.priceNote.0'
                    : 'premium.annually.priceNote.0',
                )}
              </span>
              <br />
              <span>
                {t(
                  isMonthly
                    ? 'premium.monthly.priceNote.1'
                    : 'premium.annually.priceNote.1',
                )}
              </span>
            </>
          }
          title={t('premium.title')}
        />
      </div>
      <div className="flex items-center justify-center mt-10 text-sm font-light leading-3.5">
        <div>
          <span className="block">{t('switcher.left.0')}</span>
          <span>{t('switcher.left.1')}</span>
        </div>
        <div className="mx-6 relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
          <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-200 cursor-pointer">
            <input
              checked={!isMonthly}
              type="checkbox"
              name="toggle"
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              onChange={() => setMonthly(value => !value)}
            />
          </label>
        </div>
        <div>
          <span className="block">{t('switcher.right.0')}</span>
          <span>
            {t('switcher.right.1')}{' '}
            <span className="text-blue font-bold">{t('switcher.right.2')}</span>
          </span>
        </div>
      </div>
    </section>
  )
}

export { SectionPricingPlans }
