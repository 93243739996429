import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../styles/global.css'
import { ContentMain } from '../components/common/content-main'
import { ContentSubPage } from '../components/common/content-sub-page'
import { HeaderSubPage } from '../components/common/header-sub-page'
import { CookiesBox } from '../components/cookies-box'
import { Footer } from '../components/footer'
import { Navigation } from '../components/navigation'
import { SectionPricingPlans } from '../components/section-pricing-plans'
import { SectionPricingTable } from '../components/section-pricing-table'
import { SEO } from '../components/seo'

const Pricing = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'pricing' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderSubPage title={t('title')}>
            <div className="h-60" />
          </HeaderSubPage>
          <ContentSubPage isFull>
            <SectionPricingPlans />
            <SectionPricingTable />
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default Pricing

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
