import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

const SectionPricingTable = () => {
  const { t } = useTranslation('components', {
    keyPrefix: 'sectionPricingTable',
  })

  return (
    <section className="pricing-table">
      <table>
        <thead>
          <tr>
            <th>
              <span>&nbsp;</span>
            </th>
            <th>
              <span>{t('0.free')}</span>
            </th>
            <th>
              <span>{t('0.premium')}</span>
            </th>
          </tr>
          <tr>
            <td>
              <span>{t('1.label')}</span>
            </td>
            <td>
              <span>{t('1.free')}</span>
            </td>
            <td>
              <span>{t('1.premium')}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('2.label')}</span>
            </td>
            <td>
              <span>{t('2.free')}</span>
            </td>
            <td>
              <span>{t('2.premium')}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span>{t('3.label')}</span>
            </td>
            <td>
              <span>{t('3.free')}</span>
            </td>
            <td>
              <span>{t('3.premium')}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('4.label')}</span>
            </td>
            <td>
              <span>{t('4.free')}</span>
            </td>
            <td>
              <span>{t('4.premium')}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('5.label')}</span>
            </td>
            <td>
              <span>{t('5.free')}</span>
            </td>
            <td>
              <span>{t('5.premium')}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('6.label')}</span>
            </td>
            <td>
              <span>{t('6.free')}</span>
            </td>
            <td>
              <span>{t('6.premium')}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('7.label')}</span>
            </td>
            <td>
              <span>{t('7.free')}</span>
            </td>
            <td>
              <span>{t('7.premium')}</span>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <Link
        className="my-6 block text-black font-light text-center underline"
        to="/contact-us"
      >
        <span className="font-bold">Contact sales</span> if you have custom
        business requirements.
        Pokud máte vlastní obchodní požadavky, kontaktujte oddělení prodeje.
      </Link> */}
    </section>
  )
}

export { SectionPricingTable }
